.hero-container {
  height: 100vh;
}

.hero-wrapper {
  background-image: url('../../../Assets/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.hero-wrapper h1 {
  padding-top: 25%;
  color: var(--white);
}

/* * Phone */
@media all and (max-width: 767px) {
  .hero-wrapper h1 {
    font-size: 15vw;
  }
}

/* * Phone and Tab */
@media all and (max-width: 1025px) {
  .hero-container {
    max-height: 50vh;
  }

  .hero-wrapper {
    border-radius: 0;
  }
}

/* * Tab */
@media all and (min-width: 768px) and (max-width: 1024px) {
  .hero-wrapper h1 {
    font-size: 10vw;
  }
}

/* * Tab and Desktop */
@media all and (min-width: 768px) {
}

/* * Desktop */
@media all and (min-width: 1025px) {
  .hero-container {
    padding: 35px;
    padding-bottom: 0;
  }
}
