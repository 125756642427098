.she-said-yes-container {
  padding: 0;
  text-align: center;
}

.she-said-yes-wrapper {
  background-color: var(--light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.she-said-yes-image {
  max-width: 564px;
}

.she-said-yes-image img {
  margin-bottom: -4px;
  border-radius: 0;
}

.she-said-yes-container h2 {
  color: var(--primary);
}

.she-said-yes-text {
  padding-left: 110px;
  color: var(--dark);
}

.bible-quote {
  font-style: italic;
  max-width: 510px;
  margin-top: 14px;
  margin-bottom: 7px;
}

.she-said-yes-text p:last-of-type {
  padding-left: 2px;
}

@media all and (max-width: 767px) {
  .she-said-yes-container h2 {
    font-size: 12.5vw;
  }

  .she-said-yes-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  .she-said-yes-text {
    padding: 4rem 2rem;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .she-said-yes-image {
    max-width: 40%;
  }

  .she-said-yes-text {
    padding: 0 3rem;
  }

  .she-said-yes-container h2 {
    font-size: 2rem;
  }

  .bible-quote {
    margin-top: 5px;
  }
}

@media all and (min-width: 768px) {
  .she-said-yes-image img {
    border-radius: 0 5px 5px 0;
  }

  .she-said-yes-container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media all and (min-width: 1024px) {
}
