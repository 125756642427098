.see-you-container {
  margin: 35px;
  margin-top: 0;
  background-image: url('../../../Assets/see-you.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 690px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}

.see-you-container p {
  color: #fff;
  opacity: 0.75;
}

.see-you-container h1 {
  margin-bottom: 1rem;
}

.see-you-wrapper {
  text-align: center;
}

@media all and (max-width: 767px) {
  .see-you-container {
    margin: 0;
    padding: 1rem;
    height: 345px;
    border-radius: 0;
  }

  .see-you-container h1 {
    font-size: 15vw;
    line-height: 75%;
  }
}
