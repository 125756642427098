.our-story-container {
  padding: 140px 2rem 105px 2rem;
}

@media all and (max-width: 1024px) {
  .our-story-container {
    background: url('../../../Assets/daun-our-story-mobile.png') top no-repeat;
    background-size: contain;
  }
}

@media all and (min-width: 1024px) {
  .our-story-container {
    background: url('../../../Assets/daun-our-story-desktop.png') top left no-repeat,
      url('../../../Assets/daun-our-story-desktop.png') top right no-repeat;
  }
}
