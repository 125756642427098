.schedule-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 140px 0;
  padding-bottom: 175px;
  background: url('../../../Assets/bunga.png'), url('../../../Assets/bunga-rotated.png');
  background-repeat: no-repeat;
  background-size: 50vw;
  background-position: bottom right, top left;
}

.schedule-wrapper:nth-child(1) {
  width: 100%;
}

.schedule-container h2,
.schedule-container h3 {
  color: var(--primary);
}

.schedule-wrapper {
  text-align: center;
}

.schedule-wrapper p {
  margin-top: 18px;
}

.big-title p {
  margin-top: 0;
}

@media all and (max-width: 767px) {
  .schedule-container {
    flex-direction: column;
    padding-bottom: calc(140px + 1rem);
  }

  .schedule-wrapper:nth-child(1) {
    margin-bottom: 70px;
  }

  .schedule-wrapper:nth-child(2) {
    margin-bottom: 70px;
  }

  .schedule-wrapper p {
    margin-top: 9px;
  }

  .big-title p {
    margin-top: 0;
  }
}

@media all and (min-width: 768px) {
  .schedule-container {
    background-size: 200px;
  }

  .schedule-wrapper:nth-child(1) {
    margin-bottom: 70px;
  }
}
