.ourStoryWrapper {
  text-align: center;
  max-width: 910px;
  margin: auto;
}

.ourStoryWrapper h2 {
  color: var(--primary);
  margin-bottom: 35px;
}

.ourStoryWrapper p {
  margin-bottom: 1.25rem;
  line-height: 160%;
}

.ourStoryWrapper p:last-of-type {
  margin-bottom: 0;
}

.divider {
  max-width: 60px;
  opacity: 0.6;
  margin: auto;
  margin-bottom: 45px;
  margin-top: 45px;
}

@media all and (max-width: 767px) {
  .ourStoryWrapper h2 {
    font-size: 12.5vw;
  }
}
