* {
  box-sizing: border-box;
}

:root {
  --white: #fefff6;
  --light: #ebece4;
  --light-accent: #ddd3b2;
  --primary: #917b54;
  --dark-accent: #6c5744;
  --dark: #31373e;
  --black: #1c2024;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: unset;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--primary);
}

a:active {
  opacity: 0.75;
}

@font-face {
  font-family: 'Authentic';
  src: url(../Assets/Fonts/Authentic\ Signature.ttf);
}

@font-face {
  font-family: 'Cardo';
  src: url(../Assets/Fonts/Cardo-Regular.ttf);
}

@font-face {
  font-family: 'Cardo';
  src: url(../Assets/Fonts/Cardo-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Cardo';
  src: url(../Assets/Fonts/Cardo-Italic.ttf);
  font-style: italic;
}

html {
  font-size: 18px;
  font-family: 'Cardo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: var(--white);
}

.input-group {
  display: flex;
}

.input-group .input-wrapper {
  width: 100%;
  margin-right: 0.5rem;
}

.input-group .input-wrapper:last-of-type {
  margin-right: 0;
}

input {
  border-radius: 0.25rem;
  border: solid 1px var(--primary);
  padding: 0.75rem;
  font-size: 1rem;
  font-family: 'Cardo', serif;
  font-weight: 300;
  width: 100%;
  background-color: var(--white);
}

select {
  border-radius: 0.25rem;
  border: solid 1px var(--primary);
  padding: 0.75rem;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: var(--white);
  width: 100%;
}

option {
  font-family: 'Cardo', serif;
  font-size: 1rem;
}

.select {
  position: relative;
  min-width: 200px;
}

.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  stroke-width: 2px;
  stroke: var(--primary);
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}

.select select {
  appearance: none;
  -webkit-appearance: none;
  padding-right: 2rem;
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0 1px 3px -2px var(--primary);
  cursor: pointer;
  font-family: inherit;
  font-size: 18px;
  transition: all 150ms ease;
}

.select select:required:invalid {
  color: var(--dark-accent);
}

.select select option {
  color: var(--dark);
}

.select select option[value=''][disabled] {
  display: none;
}

.select select:focus {
  outline: none;
  border-color: var(--primary);
}

.select select:hover + svg {
  stroke: var(--primary);
}

.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.btn-group {
  display: flex;
  align-items: center;
}

.btn-group div {
  flex-grow: 1;
}

button {
  padding: 0.75rem 0;
  border: none;
  font-family: 'Cardo', sans-serif;
  font-size: 18px;
  border-radius: 0.25rem;
  width: 100%;
  position: relative;
  bottom: 0;
  transition: bottom 0.25s ease, box-shadow 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  background-color: var(--light);
  border: solid 1px var(--light);
}

button:hover {
  bottom: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button:active {
  opacity: 0.75;
}

.btn-primary {
  background-color: var(--primary);
  border: solid 1px var(--primary);
  color: #fff;
}

.btn-primary-outline {
  border: solid 1px var(--primary);
  background-color: var(--white);
  color: var(--primary);
}

.btn-secondary {
  background-color: var(--secondary);
  border: solid 1px var(--secondary);
  color: #fff;
}

.btn-secondary-outline {
  border: solid 1px var(--secondary);
  background-color: var(--white);
  color: var(--secondary);
}

p {
  line-height: 140%;
  font-family: 'Cardo';
  color: var(--dark);
}

h1 {
  font-size: 5rem;
  font-family: 'Authentic';
}

h2 {
  font-size: 2.75rem;
  font-family: 'Authentic';
}

h3 {
  font-size: 2rem;
  font-family: 'Authentic';
}

.text-button {
  color: red;
  font-weight: 600;
  transition: color 0.3s ease;
}

.text-button:hover {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
  color: var(--primary);
}

.text-button:active {
  opacity: 0.5;
}

b {
  font-weight: 700;
}

.black-bg {
  background-color: rgba(28, 32, 36, 0.5);
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.close-bg {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window {
  background-color: var(--light);
  padding: 2rem;
  border-radius: 0.25rem;
}

@media all and (max-width: 767px) {
  .btn-group {
    flex-direction: column;
  }

  .window {
    padding: 2rem 1rem;
    margin: 1rem;
  }
}

@media all and (min-width: 1024px) {
  .btn-group button {
    margin-right: 0.5rem;
  }

  .btn-group button:last-of-type {
    margin-right: 0;
  }
}
