.gift-window-container h2 {
  color: var(--primary);
  text-align: center;
  line-height: 90%;
}

.gift-window-container button {
  padding: 0.75rem 1rem;
}

.rek-box {
  border: solid 1px var(--dark);
  border-radius: 0.15rem;
  padding: 0.75rem 1rem;
  text-align: center;
  color: var(--dark);
  line-height: 140%;
}

.qr-bca-container {
  max-width: 400px;
  overflow: hidden;
}

.qr-bca-container img {
  border-radius: 0.25rem;
}
