.rsvp-container {
  background-color: var(--light);
  display: flex;
}

.rsvp-container input,
.rsvp-container button {
  font-size: 16px;
}

.rsvp-wrapper {
  margin: auto;
  padding: 90px 0;
  width: 90%;
  max-width: 500px;
}

.rsvp-container h2 {
  color: var(--primary);
}

.rsvp-container select {
  width: 60px;
  height: 53.5px;
}

@media all and (min-width: 1024px) {
  .rsvp-container {
    margin: 0 35px;
    border-radius: 5px 5px 0 0;
  }
}
